.StripeElement {
    box-sizing: border-box;
    min-height: 2.5rem;
    padding: 0.6875rem 0.75rem 0.625rem;
    background-color: transparent;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
