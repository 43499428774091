.btn {
    align-items: center;
    display: inline-flex;
}

select {
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path stroke="currentColor" stroke-width="2" d="M2 4l4 4 4-4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/></svg>')
        calc(100% - 8px) / 12px no-repeat #fff;
    padding-right: 36px;
}

.popover .popover-body {
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.5rem 1.25rem 0.5rem 0.75rem;
}

.popover .popover-header {
    background: #fff;
    padding: 0;
    border-bottom: 0;
    line-height: 1;
    position: absolute;
    top: 0;
    right: 0;
}

/* slight specificity hack */
.btn.btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
}

body {
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Poppins', 'Open Sans', Arial, Helvetica, sans-serif;
    font-weight: 600;
}

/* firefox has a 2016 bug requiring this https://pokeinthe.io/2016/04/09/black-icons-with-svg-and-csp/ */
#__SVG_SPRITE_NODE__ {
    height: 0;
    width: 0;
    position: absolute;
}
